export const AUTO_SKIP_EPISODES = 'AUTO_SKIP_EPISODES';
export const AUTO_PLAY_EPISODES = 'AUTO_PLAY_EPISODES';

export const QUEUE = 'queue'; /// default this is manual queue
export const QUEUE_AUTO = 'queueAuto';
export const AUTO_QUEUE_PLAYLIST_ID = 'autoQueuePlaylistId';

/// episodeCompleteOptions -'Play next episode' index 0 and 'Stop playing audio' - index 1
// export const EPISODE_COMPLETE_OPTION_INDEX = 'EPISODE_COMPLETE_OPTION_INDEX';

export const IMPORT_OPML_ROUTE = '/import-opml';
export const AUTH_SUCCESS_ROUTE = '/auth-success';
export const LOGIN_ROUTE = '/login';
export const BLOG_ROUTE = '/blog';
export const FEATURED_ROUTE = '/featured';
export const ROUTE_MAIN = '';

export const CREATE_TAGs = '+Tag/Folder'

export const NORMAL_PLAYLIST_ID = 'NormalPlaylistId';
export const AUTO_PLAYLIST_ID = 'SmartPlaylistId';
export const PLAYLIST_NEWEST_TO_OLDEST = 'playlistNewestToOldest';
export const PLAYLIST_NEWEST_TO_OLDEST_PODCAST =
    'playlistNewestToOldestPodcast';
export const PLAYLIST_OLDEST_TO_NEWEST = 'playlistOldestToNewest';
export const PLAYLIST_OLDEST_TO_NEWEST_PODCAST =
    'playlistOldestToNewestPodcast';
export const PLAYLIST_NEWEST_TO_OLDEST_SHUFFLE =
    'playlistNewestToOldestShuffle';

export const RESTORE_STATE_MAP = 'restoreStateMap';
export const UPDATE_TIME = 'updateTime';


/// search filter keys
export const SORT_RELEVANCE = 'sortRelevance';
export const SORT_PUB_DATE_NEW_FIRST = 'sortPubDateNewFirst';
export const SORT_PUB_DATE_OLD_FIRST = 'sortPubDateOldFirst';
export const SORT_EPISODE_COUNT_ASC = 'sortEpisodeCountAsc';
export const SORT_EPISODE_COUNT_DESC = 'sortEpisodeCountDesc';
export const SORT_EPISODE_DURATION_ASC = 'sortEpisodeDurationAsc';
export const SORT_EPISODE_DURATION_DESC = 'sortEpisodeDurationDesc';

export const IS_DARK_MODE = 'IS_DARK_MODE';